import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Startup Website Landing Page Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/startup-landing-page/"
    metaDescription="Discover Lando, a modern startup landing page template with a refreshing design that could truly help you present your MVP (Minimum Viable Product)."
    description="
    h2:Introducing Lando: A website startup landing page design template for your Minimum Viable Product
    <br/>
    Lando is a modern startup landing page template with a refreshing design that could truly help you present your MVP (Minimum Viable Product). Our <a:https://uizard.io/templates/website-templates/>website design templates</a> offer a layout that's easy to work with and readily customizable for your own use case. Lando might be the missing piece you need to kickstart your next project.
    <br/>
    h3:Minimalist, professional design, divided into sections
    <br/>
    Our in-house design team created Lando to reflect the chic, minimalist, yet professional design that you'll see across most startup landing pages out in the wild. The various sections of Lando are also easily duplicated, should you need more space to showcase your MVP or call out your unique USPs.
    <br/>
    h3:An uncomplicated way to feature your MVP
    <br/>
    Uizard enables you to create and edit the content of this startup landing page template without the noise and complexities of traditional design software, allowing you to <a:https://uizard.io/prototyping/>UI prototype</a> like never before. Those months of development might have been harsh, but now you're ready to showcase your MVP, and Lando will help you do just that.
    "
    pages={[
      "A startup landing page template with multiple useful sections, such as the main banner for your MVP (minimum viable product), testimonials, and a call-to-action",
      "A pricing page that presents different tiers, as well as a FAQs (frequently asked questions) template",
      'An "About Us" page that features your story, your mission, and your team',
      'The best way to let your visitors introduce themselves, a "Contact Us" page equipped with a form',
    ]}
    projectCode="yxnWe9j4EXHP45E0Q6qV"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Lando's startup landing page: home page"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Lando's startup landing page: about us page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Lando's startup landing page: contact page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Lando's startup landing page: pricing page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of startup landing page Lando template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/lando/lando-landing-page.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(relativePath: { eq: "templates/lando/lando-about-us.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(relativePath: { eq: "templates/lando/lando-contact.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(relativePath: { eq: "templates/lando/lando-pricing.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(relativePath: { eq: "templates/lando/lando-summary.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
